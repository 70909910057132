/* eslint-disable */

export const ICON_PATH = "images/eth_logo.svg";
export const ICON_LOGIN = "images/eth_logo_black.svg";
export const API_URL = "https://os-kiosk.inf.ethz.ch/api/v1";
export const CLI_URL = "https://os-kiosk.inf.ethz.ch/backend/files/cli";
export const CONTACT_URL = "mailto:support@inf.ethz.ch";
export const CONTACT_NAME = "ISGINF";
// Map OS name from API to correct <image-name>.svg
export const OS_ICONS = {
  "ubuntu-16.04": "images/os/ubuntu-16.svg",
  "ubuntu-16.10": "images/os/ubuntu-16.svg",
  "ubuntu-17.04": "images/os/ubuntu-18.svg",
  "ubuntu-17.10": "images/os/ubuntu-18.svg",
  "ubuntu-18.04": "images/os/ubuntu-18.svg",
  "ubuntu-18.10": "images/os/ubuntu-18.svg",
  "ubuntu-20.04": "images/os/ubuntu-18.svg",
  "win-7-64": "images/os/win-7.svg",
  "win-8.1-64": "images/os/win-10.svg",
  "win-10-64": "images/os/win-10.svg",
  "ubuntu-19.04-ansible": "images/os/ubuntu-18.svg",
  "7Workstation-64": "images/os/redhat.svg",
  "7Server-64": "images/os/redhat.svg",
  "Fedora-Workstation-24": "images/os/fedora.svg",
  "Fedora-Workstation-26": "images/os/fedora.svg",
  "netboot.xyz": "images/os/netboot.svg",
  wipe: "images/os/wipe.svg",
  none: "images/os/none.svg",
  unknown: "images/os/unknown.svg"
};
